var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { Toast } from '../../ui/toast.service';
import { Translations } from '../../translations/translations.service';
import { HttpErrorHandler } from './http-error-handler.service';
import { CurrentUser } from '../../../auth/current-user';
import * as i0 from "@angular/core";
import * as i1 from "../../translations/translations.service";
import * as i2 from "../../../auth/current-user";
import * as i3 from "@angular/router";
import * as i4 from "../../ui/toast.service";
var BackendHttpErrorHandler = /** @class */ (function (_super) {
    __extends(BackendHttpErrorHandler, _super);
    function BackendHttpErrorHandler(i18n, currentUser, router, toast, zone) {
        var _this = _super.call(this, i18n) || this;
        _this.i18n = i18n;
        _this.currentUser = currentUser;
        _this.router = router;
        _this.toast = toast;
        _this.zone = zone;
        return _this;
    }
    /**
     * Redirect user to login page or show toast informing
     * user that he does not have required permissions.
     */
    BackendHttpErrorHandler.prototype.handle403Error = function (response) {
        // if user doesn't have access, navigate to login page
        if (this.currentUser.isLoggedIn()) {
            this.showToast(response);
        }
        else {
            this.router.navigate(['/login']);
        }
    };
    BackendHttpErrorHandler.prototype.showToast = function (response) {
        var _this = this;
        this.zone.run(function () {
            var msg = 'You don\'t have required permissions to do that.';
            _this.toast.open(response.messages.general ? response.messages.general : msg);
        });
    };
    BackendHttpErrorHandler.ngInjectableDef = i0.defineInjectable({ factory: function BackendHttpErrorHandler_Factory() { return new BackendHttpErrorHandler(i0.inject(i1.Translations), i0.inject(i2.CurrentUser), i0.inject(i3.Router), i0.inject(i4.Toast), i0.inject(i0.NgZone)); }, token: BackendHttpErrorHandler, providedIn: "root" });
    return BackendHttpErrorHandler;
}(HttpErrorHandler));
export { BackendHttpErrorHandler };
