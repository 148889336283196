var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as Raven from 'raven-js';
import { NoBackendErrorHandler } from './no-backend-error-handler';
export function ravenErrorHandlerFactory(settings, currentUser) {
    return new RavenErrorHandler(settings, currentUser);
}
var RavenErrorHandler = /** @class */ (function (_super) {
    __extends(RavenErrorHandler, _super);
    /**
     * RavenErrorHandler Constructor.
     */
    function RavenErrorHandler(settings, currentUser) {
        var _this = _super.call(this, settings) || this;
        _this.settings = settings;
        _this.currentUser = currentUser;
        /**
         * Http error codes that should not be reported.
         */
        _this.dontReport = [
            401, 402, 403, 404, 422
        ];
        _this.setUserContext();
        return _this;
    }
    /**
     * Handle specified error.
     */
    RavenErrorHandler.prototype.handleError = function (err) {
        // if there's no error, or it's a validation error, bail
        if (!err || (err.type === 'http' && this.dontReport.indexOf(err.status) > -1))
            return;
        _super.prototype.handleError.call(this, err, {
            extra: { user: this.currentUser.getModel() },
        });
    };
    RavenErrorHandler.prototype.setUserContext = function () {
        if (this.currentUser.isLoggedIn()) {
            Raven.setUserContext({
                id: this.currentUser.get('id'),
                username: this.currentUser.get('display_name'),
                email: this.currentUser.get('email')
            });
        }
    };
    return RavenErrorHandler;
}(NoBackendErrorHandler));
export { RavenErrorHandler };
